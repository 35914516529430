import { store } from "@store";
import { createContext, useEffect, useState } from "react";
import useWebSocket, { ReadyState } from 'react-use-websocket';

interface WebSocketContextType {
  sendMessage: (id: string) => void;
  lastMessage?: MessageEvent<any> | null;
  socketStatus: ReadyState;
  handleSaveTrip: (id: string | null) => void;
}

export const WebSocketContext = createContext<WebSocketContextType | undefined>(undefined);

export const WebSocketProvider = ({ children }: { children: React.ReactNode }) => {
    const [tripIdSaved, setTripIdSaved] = useState<string | null>(null);
    const token = store.getState().tokenHandling.accessToken;

    const { sendMessage, lastMessage, readyState } = useWebSocket(
        'wss://api.non-prod.latam-routing.rio.cloud/ws/location', 
        {
            shouldReconnect: (closeEvent) => true,  // Reconnect automatically
            onOpen: () => {
                console.log('SOCKET: Conexão WebSocket estabelecida');
                if (tripIdSaved) {
                    handleSendMessage(tripIdSaved);
                }
            },
            onError: (error) => {
                console.error('Erro no WebSocket:', error);
            },
            onClose: () => {
                console.log('Conexão fechada');
            },
        }
    );


    const handleSendMessage = (tripId: string) => {
        if (token) {
            console.log('SOCKET: ENVIO MSG tripId ' + tripId);
            sendMessage(
                JSON.stringify({
                    action: 'subscribe',
                    token: 'Bearer ' + token,
                    tripId,
                })
            );
        }
    };

    const handleSaveTrip = (tripId: null | string) => {
        setTripIdSaved(tripId)
    }

    return (
        <WebSocketContext.Provider value={{ sendMessage: handleSendMessage, lastMessage, socketStatus: readyState, handleSaveTrip }}>
            {children}
        </WebSocketContext.Provider>
    );
};
